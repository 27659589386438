import { Card, Row, Col, Divider, Badge } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import { ReleaseService } from '../../services/releaseService';
import { ReleaseDataModel } from '../../models/releaseModel';
import moment from 'moment';
import { DateFormat } from '../../constant/constants';
import { AssetService } from '../../services/assetService';
import Table, { ColumnsType } from 'antd/es/table';
import { AssetDetailModel } from '../../models/assetModel';
import { AssetStatus, AssetType } from '../../constant/assetConstants';
import { RouteNames } from '../../components/Route/routeName';

export default function ReleaseDetail() {
    const [searchParams,] = useSearchParams();
    const [, setReleaseId] = useState('');
    const [release, setRelease] = useState<ReleaseDataModel>({} as ReleaseDataModel);
    const [assets, setAssets] = useState<AssetDetailModel[]>([]);

    const columns: ColumnsType<AssetDetailModel> = [
        {
          title: '',
          dataIndex: 'thumbnailImageInfo',
          width: 60,
          render: (value, record, index) =>
                record &&
                (record.assetType === AssetType.IMAGE ? (
                    <div style={{ position: 'relative', height: 50, width: 50 }}>
                        <img
                            src={record.thumbnailImageInfo.imageUrl}
                            style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
                        />
                    </div>
                ) : (
                    <div style={{ position: 'relative', height: 50, width: 50 }}>
                        <img
                            src={record.thumbnailImageInfo.vdoCoverUrl}
                            style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
                        />
                    </div>
                )),
        },
        {
          title: 'Asset Code',
          dataIndex: 'code',
          align: 'center',
          render: (value, record, index) => <>{record.code + ' - ' + record.name}</>,
        },
        {
          title: 'Asset Type',
          dataIndex: 'assetType',
          align: 'center',
        },
        {
            title: 'Artist',
            dataIndex: 'userId',
            align: 'center',
            render: (value, record, index) => <>{record.code + ' - ' + record.name}</>,
        },
        {
            title: 'Status',
            dataIndex: 'createAt',
            align: 'center',
            render: (value, record, index) =>
                record.status === AssetStatus.DRAFT ? (
                    <Badge status='default' text={record.status} />
                ) : record.status === AssetStatus.PENDING ? (
                    <Badge status='processing' text={record.status} />
                ) : record.status === AssetStatus.COMPLETED ? (
                    <Badge status='success' text={record.status} />
                ) : (
                    <Badge status='error' text={record.status} />
                ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (value, record, index) => <Link to={`${RouteNames.assetDetail}?id=${record.id}`}>Detail</Link>,
            align: 'center',
            width: 100,
          },
      ];

    useEffect(() => {
        const id = searchParams.get('id');
        if (id) {
            setReleaseId(id);
            onGetRelease(id);
            onGetReleaseAsset(id);
        }
    }, [searchParams]);

    const onGetRelease = async (id: string) => {
        const { data, status } = await new ReleaseService().getIdAsync(id);

        if (status !== HttpStatus.OK) {
            return;
        }

        setRelease(data);
    };

    const onGetReleaseAsset = async (id: string) => {
        const { data, status } = await new AssetService().getAssetByReleaseId(1000, id, undefined);

        if (status !== HttpStatus.OK) {
            return;
        }

        setAssets(data);
    };

    return (
        <>
            <Card title='Releases Detail' bordered={false}>
                {release && (
                    <>
                        <Row className='mt-4'>
                            <Col span={4}>Release Type</Col>
                            <Col span={8}>: {release.type}</Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col span={4}>Release Name</Col>
                            <Col span={8}>: {release.name}</Col>
                        </Row>
                        <Row className='mt-4 mb-4'>
                            <Col span={4}>File Attachment</Col>
                            <Col span={8}>
                                :{' '}
                                <Link to={release.documentUrl} target='_blank' download>
                                    Download
                                </Link>
                            </Col>
                        </Row>
                        {release.type === 'Model' && release.model && (
                            <>
                                <Divider orientation='left'>Model Description</Divider>
                                <Row className='mt-4'>
                                    <Col span={4}>Model Name</Col>
                                    <Col span={8}>: {release.model.name}</Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col span={4}>Model Ethnic</Col>
                                    <Col span={8}>: {release.model.ethnic}</Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col span={4}>Model Gender</Col>
                                    <Col span={8}>: {release.model.gender}</Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col span={4}>Model Date of birth</Col>
                                    <Col span={8}>
                                        : {moment(release.model.dateOfBirth).format(DateFormat.DateTimeWithOutTime)}
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                )}
            </Card>
            <Table className="mt-4" columns={columns} dataSource={assets} />
        </>
    );
}
