import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { HeaderConstants } from '../constant/headerConstant';
import {
  ArtistCreatingModel,
  ArtistProfileModel,
  CustomerTarget,
  UpdCompanyInfoModel,
  UpdIndividualInfoModel,
} from '../models/accountModel';
import { ListArtist } from '../models/response/ArtistModelResponse';
import { ISearchListArtist } from '../models/request/artistRequestModel';
import IChangePasswordRequest from '../models/request/changePasswordRequestModel';
import { ISearchListCustomer } from '../models/request/customerRequestModel';
import { IAdminProfileResponse } from '../models/response/AccountInfoResponse';
import { ICreateAdminRequestModel, IUpdateAdminRequestModel } from '../models/request/AdminRequestModel';

const applicationUserApi = '/ApplicationUser';

export class AccountService {
  private _qs: any = require('qs');

  signInAsync(email: string, password: string): Promise<AxiosResponse> {
    const config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    };

    return Axios.post(
      '/admin/signin',
      this._qs.stringify({
        email: email,
        password: password,
        deviceCode: HeaderConstants.deviceCode,
        scope: HeaderConstants.scope,
        clientId: HeaderConstants.clientId,
      }),
      config
    );
  }

  getArtistsAsync(
    page: number = 1,
    size: number = 10,
    searchModel?: ISearchListArtist
  ): Promise<AxiosResponse<ListArtist>> {
    const params = {
      'Paging.Page': page,
      'Paging.Size': size,
      Email: searchModel?.Email,
      Name: searchModel?.Name,
      Status: searchModel?.Status,
    };

    return Axios.get<ListArtist>(`/Artist`, { params });
  }

  getCustomerListAsync(page: number = 1, size: number = 10, searchModel?: ISearchListCustomer): Promise<AxiosResponse> {
    const params = {
      'Paging.Page': page,
      'Paging.Size': size,
      Email: searchModel?.email,
      Name: searchModel?.name,
      IsVip: searchModel?.isVip,
    };

    return Axios.get(`${applicationUserApi}/list`, { params });
  }

  async getAccountInfoAsync<T>(id: string) {
    return Axios.get(`${applicationUserApi}/${id}/account-info`);
  }

  getAdminUsersAsync(page: number, size: number, email?: string, status?: boolean): Promise<AxiosResponse> {
    const params = {
      email: email,
      page: page,
      size: size,
      status: status,
    };

    return Axios.get(`Admin/users`, { params });
  }

  getAdminUserByIdAsync(id: string): Promise<AxiosResponse<IAdminProfileResponse>> {
    return Axios.get(`Admin/users/${id}/profile`);
  }

  getSpecifyCustomerByEmailAsync(email: string): Promise<AxiosResponse<CustomerTarget>> {
    return Axios.get(`Admin/users/${email}/specifyCustomer`);
  }

  updateAdminUserAsync(form: IUpdateAdminRequestModel): Promise<AxiosResponse> {
    return Axios.put(`Admin/profile`, JSON.stringify(form));
  }

  updatePasswordAsync(request: IChangePasswordRequest): Promise<AxiosResponse> {
    return Axios.post(`${applicationUserApi}/change-password`, JSON.stringify(request));
  }

  getArtistById(id: string): Promise<AxiosResponse<ArtistProfileModel>> {
    return Axios.get<ArtistProfileModel>(`${applicationUserApi}/${id}/artist`);
  }

  updateArtistAsync(artist: ArtistCreatingModel, id: string) {
    return Axios.put(`Artist/${id}/status`, JSON.stringify(artist));
  }

  async updateIndividualAsync(form: UpdIndividualInfoModel, id: string): Promise<AxiosResponse> {
    return Axios.put(`Admin/users/${id}/individual`, JSON.stringify(form));
  }

  async updateCompanyAsync(form: UpdCompanyInfoModel, id: string) {
    return Axios.put(`Admin/users/${id}/company`, JSON.stringify(form));
  }

  async updateCompanyVipAsync(isVip: boolean, quality: number, paymentTerm: number, id: string) {
    const form = {
      isVip: isVip,
      paymentTerm: paymentTerm,
      quality: quality,
    };
    return Axios.put(`Admin/users/${id}/company/vip`, JSON.stringify(form));
  }

  registerAdminAsync(form: ICreateAdminRequestModel): Promise<AxiosResponse> {
    return Axios.post(`Admin/register-admin`, JSON.stringify(form));
  }

  lockAdminUserAsync(userId: string): Promise<AxiosResponse> {
    return Axios.post(`Admin/${userId}/lock-user`);
  }

  unlockAdminUserAsync(userId: string): Promise<AxiosResponse> {
    return Axios.post(`Admin/${userId}/unlock-user`);
  }

  getUserProfileAsync(): Promise<AxiosResponse> {
    return Axios.get(`/ApplicationUser/profile`);
  }

  activeUser(userId: string, active: boolean): Promise<AxiosResponse> {
    return Axios.put(`Admin/users/${userId}/${active ? 'inactive' : 'active'}`);
  }

  getDownloadHistoryAsync(
    page: number,
    size: number,
    assetCode?: string,
    email?: string,
    startDate?: Date,
    endDate?: Date
  ): Promise<AxiosResponse> {
    const params = {
      page: page,
      size: size,
      assetCode: assetCode,
      email: email,
      startDate: startDate,
      endDate: endDate,
    };
    return Axios.get(`Admin/users/download-history`, { params: params });
  }
}
