import { Card, Row, Col, Form, Space, Badge, Spin, Pagination } from "antd";
import Input from "../../components/Forms/Input";
import Button from "../../components/General/Button";
import RadioEvent from "../../components/Forms/RadioEvent";
import Table, { TableDataType } from "../../components/DataDisplay/Table";
import type { ColumnsType } from "antd/es/table";
import { Link, useNavigate } from "react-router-dom";
import { AccountService } from "../../services/accountService";
import React, { useEffect, useState } from "react";
import stringFormat from "../../helper/stringFormat";
import { HttpStatusCode } from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { IAdminUsersResponse } from "../../models/response/AccountInfoResponse";

const statusOptions = [
  { label: "All", value: "" },
  {
    label: "Disabled",
    value: true,
  },
  {
    label: "Enabled",
    value: false,
  },
];

interface IAdminUsersModel extends TableDataType {
  key: React.Key;
  admin: string;
  email: string;
  role: string;
  status: string;
  action: any;
}

const columns: ColumnsType<IAdminUsersModel> = [
  {
    title: "Admin",
    dataIndex: "admin",
    align: "center",
    render: (text) => <p className="text-left">{text}</p>,
  },
  {
    title: "Email",
    dataIndex: "email",
    render: (text) => <p className="text-left">{text}</p>,
    align: "center",
  },
  {
    title: "Role",
    dataIndex: "role",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value, record, index) =>
      record.status === "Enabled" ? (
        <Badge status="processing" text={record.status} />
      ) : (
        <Badge status="error" text={record.status} />
      ),
    align: "center",
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (text) => <p>{text}</p>,
    align: "center",
  },
];

const Admin: React.FC = () => {
  const [data, setData] = useState<IAdminUsersModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [form] = Form.useForm();
  const accountService = new AccountService();
  const navigate = useNavigate();

  const validateMessages = {
    required: "${label} is required!",
  };

  const onFinish = (value: any) => {
    getAdminAsync(page, size, value.email, value.status);
  };

  useEffect(() => {
    getAdminAsync(page, size);
  }, [page, size]);

  const getAdminAsync = async (
    page: number,
    size: number,
    email?: string,
    status?: boolean
  ) => {
    setLoading(true);
    const { data, status: responseStatus } =
      await accountService.getAdminUsersAsync(page, size, email, status);

    if (responseStatus !== HttpStatusCode.Ok) {
      alert("Get admin users fails");
      setLoading(false);
      return;
    }

    const listData: IAdminUsersModel[] = [];
    const responseData = data.list as IAdminUsersResponse[];
    setTotalRows(data.paging.totalRows);

    responseData.forEach((adminUsers) => {
      listData.push({
        key: adminUsers.applicationUser.id,
        admin: stringFormat.concatStringWithSpace(
          adminUsers.applicationUser.firstname,
          adminUsers.applicationUser.lastname
        ),
        email: adminUsers.email,
        role: adminUsers.roles.join(", "),
        status: !adminUsers.lockoutEnabled ? "Enabled" : "Disabled",
        action: (
          <Link to={`/admin/detail/${adminUsers.applicationUser.id}`}>
            Detail
          </Link>
        ),
      });
    });

    setData(listData);
    setLoading(false);
  };

  const onChangePage = (page: number, size: number) => {
    setPage(page);
    setSize(size);
  };

  return (
    <>
      <Card title="Admin" bordered={false}>
        <Card className="card-form">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            validateMessages={validateMessages}
          >
            <Row justify="center">
              <Col span={6}>
                <Input placeholder="Email" name="email" label="Email" />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={6}>
                <RadioEvent
                  name="status"
                  label="Status"
                  options={statusOptions}
                />
              </Col>
            </Row>
            <Row justify="end">
              <Col span={24} className="text-right">
                <Space wrap>
                  <Button
                    type="primary"
                    text="Search"
                    className="px-5"
                    onClick={() => form.submit()}
                  />
                  <Button
                    text="Clear"
                    className="px-5"
                    onClick={() => {
                      form.resetFields();
                      getAdminAsync(1, 10);
                      setPage(1);
                      setSize(10);
                    }}
                  />
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
        <Row className="mt-3">
          <Col span={8}>
            <Button
              text="Invite"
              className="px-5"
              type="primary"
              onClick={() => navigate("invite-Admin")}
            />
          </Col>
        </Row>
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row>
            <Col span={24}>
              <Table className="mt-4" columns={columns} dataSource={data} />
            </Col>
            <Col span={24} className="text-right pt-3">
              <Pagination
                current={page}
                pageSize={size}
                defaultCurrent={1}
                total={totalRows}
                onChange={onChangePage}
              />
            </Col>
          </Row>
        </Spin>
      </Card>
    </>
  );
};

export default Admin;
